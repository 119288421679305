import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./download-app.module.css"

import itunesBadge from "../../images/itunes-badge.svg"
import androidBadge from "../../images/android-badge.svg"

export default function DownloadApp({ iosUrl, androidUrl }) {
  const { t } = useTranslation()

  return (
    <div className={styles.downloadApp}>
      <a
        href={iosUrl}
        className={styles.button}
        target="_blank"
        rel="noreferrer"
      >
        <img alt={`${t("pages.home.button.ios.alt")}`} src={itunesBadge} />
      </a>
      <a
        href={androidUrl}
        className={styles.button}
        target="_blank"
        rel="noreferrer"
      >
        <img alt={`${t("pages.home.button.android.alt")}`} src={androidBadge} />
      </a>
    </div>
  )
}
