import React, { useEffect } from "react"
import Container from "react-bootstrap/Container"
import { useTranslation } from "react-i18next"

import { itunesLink, googlePlayLink } from "../constants/links"

/* Images */
import MainLogo from "../images/logo.svg"

/* Components */
import Wrapper from "../components/wrapper/wrapper"
import SEO from "../components/seo/seo"
import Main from "../components/main/main"
import Content from "../components/content/content"
import Logo from "../components/logo/logo"
import Title from "../components/title/title"
import DownloadApp from "../components/download-app/download-app"

export default function App({ location }) {
  const { t } = useTranslation()

  const PageTitle = t("pages.home.title")

  useEffect(() => {
    var TIMEOUT = 1 * 1000 // 1 second
    var ua = navigator.userAgent.toLowerCase()
    var params = window.location.search

    function redirect(target) {
      setTimeout(function () {
        window.location = target
      }, TIMEOUT)
    }

    if (ua.indexOf("android") > -1) {
      // If params = concat
      if (params) {
        var newAndroidLink = googlePlayLink.concat(params.replace("?", "&"))
        redirect(newAndroidLink)
      } else {
        redirect(googlePlayLink)
      }
    } else if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1) {
      // If params = concat & replace ?
      if (params) {
        var newIosLink = itunesLink.concat(params.replace("?", "&"))
        redirect(newIosLink)
      } else {
        redirect(itunesLink)
      }
    }
  }, [])

  return (
    <Wrapper>
      <SEO />
      <Main>
        <Container>
          <Content>
            <Logo logo={MainLogo} />
            <Title title={PageTitle} />
            <DownloadApp iosUrl={itunesLink} androidUrl={googlePlayLink} />
          </Content>
        </Container>
      </Main>
    </Wrapper>
  )
}
