import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./logo.module.css"

export default function Logo({ logo }) {
  const { t } = useTranslation()

  return <img src={logo} className={styles.logo} alt={`${t("image.alt")}`} />
}
